import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"

const HeaderDiv = styled.header`
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;

    @media only screen and (max-width: 1140px) {
        padding: 0 20px;
    }
`;

const StyledLink = styled(Link)`
`;

const StyledMenuLink = styled(Link)`
    color: var(--blue);
    font-size: 15px;
    font-weight: 600;
`;

const CustomerLoginButton = styled.a`
    border: 1px solid var(--blue);
    border-radius: 2px;
    color: var(--blue);
    font-size: 16px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    padding: 0px 6px;

    &:focus, &:hover {
        outline: none;
        background: rgba(35, 192, 245, 0.1);
      }

      &:active {
        background: rgba(35, 192, 245, 0.2);
      }

    @media only screen and (max-width: 1140px) {
        width: 160px;
        height: 50px;
        padding-right: 10px;
        padding-left: 28px;

    }
`;

const MenuDiv = styled.div`
    @media only screen and (max-width: 1140px) {
        position: absolute;
        left: 0;
        background: var(--white);
        width: 100%;
        height: 100vh;
        top: 70px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    > ul {
        width: 100%;
        margin: 0;
        text-align: center;
        transition: all .25s ease-out;
        font-weight: 500;
        list-style-type:none;

        @media only screen and (max-width: 1140px) {
            list-style-type:none;
            text-align: left;
            opacity: ${({ menuOpen }) => menuOpen ? '1' : '0'};
            display: ${({ menuOpen }) => menuOpen ? 'block' : 'none'};
            font-weight: 600;
            padding: 30px;
            padding-bottom: 150px;
        }
    }

    li {
        padding-right: 25px;
        margin-bottom: 0;

        @media only screen and (min-width: 1140px) {
            display: inline-block;
            list-style: none;
        }

        &:last-of-type {
            padding-right: 0;
        }

        &:hover {
            ul {
                display: block;
            }
        }
    }

    a {
        display: flex;
        align-items: center;
        font-size: 16px;
    }

    svg {
        margin-left: 5px;
    }

    @media only screen and (max-width: 1140px) {
        display: ${({ menuOpen }) => menuOpen ? 'block' : 'none'};
        ul {
            width: 100%;
        }

        li {
            margin-bottom: 15px;
            width: 100%;
            float: none;
            text-align: left;

            svg {
                display: none;
            }

        }
    }
`;

const LogoDiv = styled.div`
    display: inline-block;
    vertical-align: middle;
    flex: 0 0 182px;
    margin-top: 3px;
`;

const SubMenuUl = styled.ul`
    display:none;
    width: 127px;

    @media only screen and (min-width: 1140px) {
        background: var(--white);
        text-align: right;
        transition: all .25s ease-out;
        position: absolute;
        border: 1px solid #D4D9E0;
        box-shadow: rgba(61, 123, 178, 0.4) 0px 6px 10px 0px;
        transition: box-shadow .8s ease-in-out;
        top: 40px;
        list-style: none;
        list-style-type:none;
        padding: 0;
        margin-left: 0;
        padding: 15px 18px 10px;

        li {
            padding-bottom: 5px;

            a {
                letter-spacing: .1px;
                line-height: 20px;
            }
        }
    }

    &.company {
        right: 270px;
    }

    &.products {
        width: 100%;
        @media only screen and (min-width: 1140px) {
            margin-left: -164px;
            width: 230px;
        }
    }

    &.services {
        width: 100%;
        @media only screen and (min-width: 1140px) {
            margin-left: -113px;
            width: 170px;
        }
    }

    @media only screen and (min-width: 1140px) {
        &:before {
            height: 0;
            content: '';
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #D4D9E0;
            position: absolute;
            top: -10px;
            right: 8px;
        }

        &:after {
            height: 0;
            content: '';
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #fff;
            position: absolute;
            top: -8px;
            right: 10px;
        }
    }

    @media only screen and (max-width: 1140px) {
        display: block;

        a {
            text-align: left !important;
            font-weight: 400 !important;
        }
    }
`;

const SubMenuLi = styled.li`
    user-select: none;
    letter-spacing: -1px;
    margin-left: 0;
    display: block;
    float:left;
    width: 100%;

    a {
        font-size: 16px;
        color: var(--blue);
        width: 100%;
        text-align: right;
        display: block;
        line-height: 24px;
        font-weight: 500;
    }

`;

const SubStyledLink = styled(Link)`
    text-decoration: none;
`

const MenuIconDiv = styled.div`
    display: none;
    @media only screen and (max-width: 1140px) {
        display: inline-block;
    }
`
const lockMenu = ({ targetElement, bodyScrollPos }) => {
    targetElement.style.position = 'fixed';
    targetElement.style.height = '100%';
    targetElement.style.width = '100%';
    targetElement.style.top = `-${bodyScrollPos}px`
};

const unLockMenu = ({ targetElement, bodyScrollPos, documentElement }) => {
    targetElement.style.removeProperty('position');
    targetElement.style.removeProperty('top');
    targetElement.style.removeProperty('height');
    targetElement.style.removeProperty('width');
    documentElement.scrollTop = targetElement.scrollTop = bodyScrollPos;
};

const chevronSVG = () => (
    <svg width="7px" height="5px" viewBox="0 0 7 5" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Desktop---HOME" transform="translate(-1254.000000, -51.000000)" stroke="#6A7C92">
                <g id="Navigation">
                    <polyline id="Chevron" points="1254 52 1257.11912 55 1260 52"></polyline>
                </g>
            </g>
        </g>
    </svg>
);

const logoSvg = () => (
    <svg width="300" height="39" viewBox="0 0 300 39" fill="none">
        <g clipPath="url(#clip0_189_12682)">
        <path d="M48.8584 8.83887H54.2859V27.8661H66.0437V32.2856H48.869V8.83887H48.8584Z" fill="#3C7BB1"/>
        <path d="M85.8522 27.2606H74.9654L72.8836 32.2856H67.3286L77.78 8.83887H83.1332L93.6165 32.2856H87.9234L85.8522 27.2606ZM84.1422 23.1385L80.4247 14.1614L76.7073 23.1385H84.1422Z" fill="#3C7BB1"/>
        <path d="M94.7319 8.83887H105.385C107.934 8.83887 110.175 9.32756 112.13 10.2943C114.084 11.2611 115.603 12.6316 116.686 14.3951C117.77 16.1587 118.311 18.2091 118.311 20.5569C118.311 22.9048 117.77 24.9552 116.686 26.7187C115.603 28.4823 114.084 29.8528 112.13 30.8195C110.175 31.7863 107.924 32.275 105.385 32.275H94.7319V8.83887ZM105.109 27.8236C107.456 27.8236 109.326 27.1756 110.717 25.8688C112.108 24.5621 112.809 22.7879 112.809 20.5569C112.809 18.3259 112.108 16.5518 110.717 15.245C109.326 13.9383 107.456 13.2902 105.109 13.2902H100.149V27.8236H105.109Z" fill="#3C7BB1"/>
        <path d="M120.382 8.83887H131.036C133.585 8.83887 135.826 9.32756 137.78 10.2943C139.734 11.2611 141.253 12.6316 142.337 14.3951C143.42 16.1587 143.962 18.2091 143.962 20.5569C143.962 22.9048 143.42 24.9552 142.337 26.7187C141.253 28.4823 139.734 29.8528 137.78 30.8195C135.826 31.7863 133.574 32.275 131.036 32.275H120.382V8.83887ZM130.759 27.8236C133.107 27.8236 134.976 27.1756 136.367 25.8688C137.759 24.5621 138.46 22.7879 138.46 20.5569C138.46 18.3259 137.759 16.5518 136.367 15.245C134.976 13.9383 133.107 13.2902 130.759 13.2902H125.799V27.8236H130.759Z" fill="#3C7BB1"/>
        <path d="M156.144 9.47632C158.152 9.47632 159.798 9.84815 161.296 11.0168C162.931 12.2598 163.909 14.1933 163.909 16.743C163.909 19.2927 162.836 21.3644 161.094 22.6392C159.49 23.776 157.578 24.0097 155.635 24.0097H148.805V32.0413C148.805 32.1794 148.709 32.275 148.571 32.275H147.435C147.297 32.275 147.201 32.1794 147.201 32.0413V9.71004C147.201 9.57193 147.307 9.47632 147.435 9.47632H156.144ZM160.085 12.0898C158.885 11.2186 157.536 10.8893 155.836 10.8893H148.805V22.6073H155.369C157.005 22.6073 158.651 22.4374 159.926 21.5662C161.402 20.5569 162.305 18.8253 162.305 16.743C162.305 14.6607 161.466 13.0565 160.095 12.0898H160.085Z" fill="#959FA7"/>
        <path d="M163.675 32.0413C163.611 32.1794 163.505 32.275 163.335 32.275H162.23C162.092 32.275 161.997 32.2113 161.997 32.1051C161.997 32.0413 161.997 31.967 162.06 31.8395L171.195 9.66758C171.258 9.43386 171.365 9.37012 171.566 9.37012H172.735C172.937 9.37012 173.032 9.43386 173.107 9.66758L182.241 31.8076C182.273 31.9032 182.336 32.0413 182.336 32.1051C182.336 32.2007 182.241 32.275 182.103 32.275H180.934C180.733 32.275 180.637 32.1794 180.595 32.0413L178.152 26.1451H166.065L163.654 32.0413H163.675ZM172.151 11.3461L166.564 24.8384H177.748L172.161 11.3461H172.151Z" fill="#959FA7"/>
        <path d="M194.477 22.8729L201.646 31.8819C201.784 32.0838 201.784 32.2856 201.508 32.2856H200.234C199.968 32.2856 199.905 32.2538 199.766 32.0519L192.565 22.9154H186.076V32.0519C186.076 32.19 185.98 32.2856 185.842 32.2856H184.705C184.567 32.2856 184.472 32.19 184.472 32.0519V9.71004C184.472 9.57193 184.578 9.47632 184.705 9.47632H193.372C195.55 9.47632 197.26 9.67817 198.832 10.6874C200.467 11.6967 201.54 13.4709 201.54 16.2118C201.54 18.9528 200.436 20.5676 198.832 21.6406C197.525 22.5117 196.251 22.8092 194.477 22.8836V22.8729ZM186.076 10.8468V21.5343H192.937C194.806 21.5343 196.314 21.4387 197.589 20.6951C198.927 19.8877 199.905 18.5172 199.905 16.2118C199.905 13.9064 198.97 12.4935 197.663 11.7286C196.357 10.9955 194.881 10.8574 193.075 10.8574H186.076V10.8468Z" fill="#959FA7"/>
        <path d="M220.159 9.47632C220.297 9.47632 220.393 9.57193 220.393 9.71004V10.6768C220.393 10.7724 220.297 10.9105 220.159 10.9105H212.087V32.0413C212.087 32.1794 211.991 32.275 211.885 32.275H210.717C210.621 32.275 210.483 32.1794 210.483 32.0413V10.9105H202.453C202.315 10.9105 202.22 10.7724 202.22 10.6768V9.71004C202.22 9.57193 202.315 9.47632 202.453 9.47632H220.159Z" fill="#959FA7"/>
        <path d="M240.988 9.71004C240.988 9.57193 241.126 9.47632 241.221 9.47632H242.326C242.464 9.47632 242.56 9.57193 242.56 9.71004V32.0413C242.56 32.1794 242.464 32.275 242.326 32.275H241.189C240.988 32.275 240.892 32.2431 240.722 32.0413L224.652 12.0473V32.0413C224.652 32.1794 224.514 32.275 224.418 32.275H223.314C223.176 32.275 223.08 32.1794 223.08 32.0413V9.71004C223.08 9.57193 223.186 9.47632 223.314 9.47632H224.418C224.62 9.47632 224.716 9.54006 224.854 9.74191L240.988 29.7678V9.71004Z" fill="#959FA7"/>
        <path d="M262.124 9.47632C262.262 9.47632 262.358 9.57193 262.358 9.71004V10.6768C262.358 10.7724 262.262 10.9105 262.124 10.9105H248.061V20.1532H260.414C260.52 20.1532 260.648 20.2914 260.648 20.387V21.3537C260.648 21.4918 260.51 21.5875 260.414 21.5875H248.061V30.862H262.124C262.262 30.862 262.358 30.9577 262.358 31.0958V32.0307C262.358 32.1688 262.262 32.2644 262.124 32.2644H246.691C246.553 32.2644 246.458 32.1688 246.458 32.0307V9.71004C246.458 9.57193 246.564 9.47632 246.691 9.47632H262.124Z" fill="#959FA7"/>
        <path d="M275.454 22.8729L282.623 31.8819C282.762 32.0838 282.762 32.2856 282.485 32.2856H281.211C280.945 32.2856 280.882 32.2538 280.743 32.0519L273.542 22.9154H267.053V32.0519C267.053 32.19 266.957 32.2856 266.819 32.2856H265.682C265.544 32.2856 265.449 32.19 265.449 32.0519V9.71004C265.449 9.57193 265.555 9.47632 265.682 9.47632H274.349C276.527 9.47632 278.237 9.67817 279.809 10.6874C281.444 11.6967 282.517 13.4709 282.517 16.2118C282.517 18.9528 281.413 20.5676 279.809 21.6406C278.502 22.5117 277.228 22.8092 275.454 22.8836V22.8729ZM267.053 10.8468V21.5343H273.914C275.783 21.5343 277.292 21.4387 278.566 20.6951C279.904 19.8877 280.882 18.5172 280.882 16.2118C280.882 13.9064 279.947 12.4935 278.64 11.7286C277.334 10.9955 275.858 10.8574 274.052 10.8574H267.053V10.8468Z" fill="#959FA7"/>
        <path d="M284.759 30.4052C284.621 30.299 284.557 30.2034 284.621 30.0652L285.088 29.0985C285.152 28.9604 285.258 28.8966 285.417 29.0347C286.787 29.9378 288.901 31.2445 292.215 31.2445C294.02 31.2445 295.465 30.8089 296.463 30.0759C297.674 29.1728 298.269 27.8342 298.269 26.2194C298.269 24.2753 297.026 23.0748 295.359 22.2992C293.288 21.3325 291.078 21.0987 288.434 19.9514C286.32 19.0484 284.78 17.5716 284.78 15.0963C284.78 13.354 285.385 11.9198 286.554 10.9424C287.86 9.77377 289.867 9.16821 292.544 9.16821C294.955 9.16821 297.26 9.77377 298.842 10.7405C299.012 10.8043 299.044 10.9424 298.949 11.0805L298.513 12.0154C298.481 12.1535 298.343 12.1854 298.141 12.0791C296.431 11.0699 294.286 10.5706 292.48 10.5706C290.239 10.5706 288.593 11.1017 287.594 12.0473C286.819 12.7803 286.458 13.8214 286.458 15.0326C286.458 16.9448 287.796 18.0497 289.942 18.8784C292.353 19.7814 294.626 20.0151 296.878 21.1944C298.481 22.0655 299.99 23.606 299.99 26.0494C299.99 28.2592 299.151 29.9378 297.674 31.0426C296.336 32.0838 294.456 32.615 292.215 32.615C288.763 32.615 286.352 31.4463 284.748 30.4052H284.759Z" fill="#959FA7"/>
        <path d="M31.6089 34.7822C30.2919 33.348 28.1995 32.9336 26.4576 33.7835C20.2972 36.7794 12.6604 35.5471 7.79584 30.2352C2.93127 24.9339 2.34709 17.2104 5.86276 11.3354C6.86117 9.66749 6.6275 7.54273 5.31045 6.11914C-1.64654 13.4921 -1.8271 25.0933 5.14051 32.6787C12.0975 40.2641 23.6748 41.0821 31.6195 34.7715L31.6089 34.7822Z" fill="#3C7BB1"/>
        <path d="M15.7197 32.6469C16.973 33.0081 18.2795 33.2099 19.6496 33.2099C20.9029 33.2099 22.1244 33.0293 23.2821 32.7213V17.4336C20.9985 18.4322 18.5131 19.144 15.7197 19.6009V32.6469Z" fill="#3C7BB1"/>
        <path d="M6.66992 24.7853C8.15691 28.1 10.8654 30.7453 14.2323 32.137V20.0684C11.4177 20.4189 9.3465 20.7802 6.66992 20.6739V24.7853Z" fill="#3C7BB1"/>
        <path d="M31.3968 12.2598C29.9841 13.5559 27.0845 15.7338 24.769 16.8599V32.2431C28.0617 30.9683 30.7489 28.5142 32.3314 25.4014V13.5665C32.3314 13.5665 31.4817 12.366 31.3968 12.2598Z" fill="#3C7BB1"/>
        <path d="M26.8296 1.43422C20.4887 -1.13674 13.0325 -0.265592 7.39258 4.20703C8.70963 5.64125 10.802 6.05557 12.5439 5.20567C15.3692 3.82457 18.5025 3.35713 21.5402 3.75021L23.399 2.95342C24.2274 2.59221 25.4914 2.03977 26.8296 1.43422Z" fill="#959FA7"/>
        <path d="M35.2523 7.99951L35.0399 11.4629L34.8062 15.2237C35.9639 19.3776 35.401 23.8821 33.1493 27.6429C32.1509 29.3109 32.3846 31.4356 33.7016 32.8592C40.1594 26.0175 40.7648 15.5318 35.2523 7.99951Z" fill="#959FA7"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.1208 4.62124C24.8643 4.93995 25.8415 5.4499 26.8824 6.05545C25.5759 8.5308 20.4671 15.5106 6.58496 18.9528C21.5717 18.4428 28.8792 11.6436 31.2902 9.15761C32.1399 9.90127 32.8303 10.6449 33.2021 11.3355L33.8818 0.339844C33.6375 0.339844 26.7337 3.48449 24.1102 4.61061L24.1208 4.62124Z" fill="#959FA7"/>
        </g>
        <defs>
        <clipPath id="clip0_189_12682">
        <rect width="300" height="39" fill="white"/>
        </clipPath>
        </defs>
    </svg>
);

const subMenu = () => {
    return (
        <SubMenuUl className="company">
            <SubMenuLi>
                <SubStyledLink
                    to="/company/about"
                >
                About
                </SubStyledLink>
            </SubMenuLi>
            <SubMenuLi>
                <SubStyledLink
                    to="/company/case-studies"
                >
                Case Studies
                </SubStyledLink>
            </SubMenuLi>
            <SubMenuLi>
            <SubStyledLink
                    to="/company/team"
                >
                Team
                </SubStyledLink>
            </SubMenuLi>
            <SubMenuLi>
                <SubStyledLink
                    to="/company/blog"
                >
                Blog
                </SubStyledLink>
            </SubMenuLi>
            <SubMenuLi>
                <SubStyledLink
                    to="/company/careers"
                >
                Careers
                </SubStyledLink>
            </SubMenuLi>
        </SubMenuUl>
    )
}

const subMenuProduct = () => {
    return (
        <SubMenuUl className="products">
             <SubMenuLi>
                <SubStyledLink
                    to="/solutions/cannabisessentials"
                >
                Cannabis Essentials ERP
                </SubStyledLink>
            </SubMenuLi>
            <SubMenuLi>
                <SubStyledLink
                    to="/solutions/concurquickconnect"
                >
                Concur QuickConnect
                </SubStyledLink>
            </SubMenuLi>
            <SubMenuLi>
                <SubStyledLink
                    to="/solutions/quickconnect"
                >
                 Field Service QuickConnect
                </SubStyledLink>
            </SubMenuLi>
            <SubMenuLi>
                <SubStyledLink
                    to="/solutions/supplychain"
                >
                 Supply Chain Education
                </SubStyledLink>
            </SubMenuLi> 
            <SubMenuLi>
                <SubStyledLink
                    to="/solutions/enhancedcyclecounting"
                >
                 Enhanced Cycle Counting
                </SubStyledLink>
            </SubMenuLi> 
            <SubMenuLi>
                <SubStyledLink
                    to="/solutions/psa"
                >
                 Ladd PSA Lite
                </SubStyledLink>
            </SubMenuLi> 
        </SubMenuUl>
    )
}

const subMenuServices = () => {
    return (
        <SubMenuUl className="services">
             <SubMenuLi>
                <SubStyledLink to="/services/certinia"> Certinia </SubStyledLink>
            </SubMenuLi>
            <SubMenuLi>
                <SubStyledLink
                    to="/services/rootstock"
                >
                 Rootstock
                </SubStyledLink>
            </SubMenuLi>
            <SubMenuLi>
                <SubStyledLink
                    to="/services/salesforce"
                >
                    Salesforce
                </SubStyledLink>
            </SubMenuLi> 
            <SubMenuLi>
                <SubStyledLink
                    to="/salesforceplatform">
                    Salesforce Platform
                </SubStyledLink>
            </SubMenuLi> 
            <SubMenuLi>
                <SubStyledLink
                    to="/services/managed-services"
                >
                    Managed Services
                </SubStyledLink>
            </SubMenuLi> 
        </SubMenuUl>
    )
}

function trackClick() {
    window.gtag("event", "atlassian_click");
 };


const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuButtonOpen = () => setMenuOpen(true);
    const menuButtonClose = () => setMenuOpen(false);
    let innerHeight = 0;

    useEffect(() => {
        if(document){
            let targetElement = document.getElementsByTagName('body')[0];
            let targetMenuElement = document.querySelectorAll('.hamburger')[0];

            let documentElement = document.documentElement;
            let bodyScrollPos = targetElement.scrollTop || documentElement.scrollTop || 0;
            if (menuOpen) {
                targetMenuElement.classList.add('hamburger--spin');
                targetMenuElement.classList.add('active');
                lockMenu({ targetElement, bodyScrollPos });
            } else {
                targetMenuElement.classList.remove('hamburger--spin');
                targetMenuElement.classList.remove('active');
                unLockMenu({ targetElement, bodyScrollPos, documentElement })
            }
        }
        innerHeight = window.innerHeight;
    }, [menuOpen]);

    return (
        <>
            <HeaderDiv>
                <WrapperDiv>
                    <LogoDiv>
                        <StyledLink to="/" >
                            {logoSvg()}
                        </StyledLink>
                    </LogoDiv>
                    <MenuIconDiv
                        className="hamburger"
                        onClick={menuOpen ? menuButtonClose : menuButtonOpen}
                        menuOpen={menuOpen}>

                        <span className="hamburger-box"> <span className="hamburger-inner"></span> </span>
                    </MenuIconDiv>
                    <MenuDiv menuOpen={menuOpen}>
                        <ul>
                            <li className="has-kids"><StyledMenuLink to="#" rel="nofollow"className="cursor-default" onClick={(evt) => { evt.preventDefault(); }} activeClassName="active">Services {chevronSVG()}
                            </StyledMenuLink>{subMenuServices()}</li>
                            
                            <li className="has-kids"><StyledMenuLink to="#" rel="nofollow" className="cursor-default" onClick={(evt) => { evt.preventDefault(); }} activeClassName="active">
                                Solutions {chevronSVG()}
                            </StyledMenuLink>{subMenuProduct()}</li>
                            <li className="has-kids"><StyledMenuLink to="#" rel="nofollow" className="cursor-default" onClick={(evt) => { evt.preventDefault(); }}  activeClassName="active">
                                Company {chevronSVG()}
                            </StyledMenuLink>
                            {subMenu()}</li>
                            <li><StyledMenuLink to="/company/contact-us" activeClassName="active">
                                Contact Us
                            </StyledMenuLink></li>

                            <li><CustomerLoginButton onClick={trackClick} href="https://laddpartners.atlassian.net/servicedesk/customer/user/login?destination=portals" target="_blank">Customer Login</CustomerLoginButton></li>
                        </ul>
                    </MenuDiv>
                </WrapperDiv>
            </HeaderDiv>
        </>
    );
}

export default Header